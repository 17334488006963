import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const QUERY = graphql`
  query {
    products: allContentfulProduct(filter: {showOnAccessories: {eq: true}}) {
      edges {
        node {
          product
          slug
          images {
            description
            fluid(maxWidth: 480, maxHeight: 320) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            description
          }
      	}
    	}
  	}
	}
`

const Acessorios = () => {
  const { products } = useStaticQuery(QUERY)

  return (
    <Layout>
      <SEO description='Linha de acessórios completa para completar seus produtos.' />
      <h1 className='invisible'>Acessórios</h1>
      <div className='w-11/12 mx-auto mt-2 md:w-4/5'>
      <p className='flex robika w-full sm:w-4/5 lg:w-2/3 uppercase underlin text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl md:mt-10 mb-2 font-bold'>acessórios</p>
      <p className='text-lg font-thin text-xs md:text-base lg:text-lg xl:text-xl'>Os acessórios são vendidos separadamente sendo adequados de acordo com o pedido do cliente.</p>

       <section className='mx-auto vitrine'>
        <div className='flex items-center flex-col pt-4 md:pt-6 pb-12'>
          {
            products.edges.map( product => {
              return (
                <div key={product.node.slug} className='w-full md:w-2/3 flex flex-row bg-gray-600 my-2'>
                  <Img className='w-1/2' fluid={product.node.images[0].fluid} alt={product.node.images[0].description}/>
                  <div className='w-1/2 pt-3 flex flex-col m-2'>
                    <p className='pl-2  text-xs md:text-base lg:text-lg xl:text-xl font-bold text-white pb-2 underline'>{product.node.product}</p>
                    <p className='pl-2 text-xs xl:text-sm text-white'>{product.node.description.description}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </section>
      </div>
    </Layout>
  )
}

export default Acessorios